import React from 'react';
import Page from '../components/layout/Page';
import TonsList from '../components/containers/TonsList';

const TonsPage: React.FC = () => {
  return (
    <Page title='Transactions' maxWidth='xl'>
      <TonsList />
    </Page>
  );
};

export default TonsPage;