import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  TextField
} from '@mui/material';
import CompanySelect from '../containers/company/CompanySelect';
import { Model } from '../../types/model';

type EditModelDialogProps = DialogProps & {
  init: Model;
  onClose: () => void;
  onAccept: (model: Model) => void;
}

const EditModelDialog: React.FC<EditModelDialogProps> = ({ open, onAccept, onClose, init, ...props }) => {
  const [data, setData] = useState<Model>(init);
  useEffect(() => setData(init), [init]);

  return (
    <Dialog open={open} {...props}>
      <DialogTitle>Add a New Model</DialogTitle>
      <DialogContent>
        <CompanySelect value={data.companyId} onChange={(e) => setData(old => ({ ...old, companyId: e.target.value }))} />
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          fullWidth
          value={data.name}
          onChange={(e) => setData(old => ({ ...old, name: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Alias"
          fullWidth
          value={data.alias}
          onChange={(e) => setData(old => ({ ...old, alias: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Avatar URL"
          fullWidth
          value={data.avatarUrl}
          onChange={(e) => setData(old => ({ ...old, avatarUrl: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Bot Link"
          fullWidth
          value={data.botLink}
          onChange={(e) => setData(old => ({ ...old, botLink: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Telegram Bot Secret Key"
          fullWidth
          value={data.telegramBotSecretKey}
          onChange={(e) => setData(old => ({ ...old, telegramBotSecretKey: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Control Bot Link"
          fullWidth
          value={data.controlBotLink}
          onChange={(e) => setData(old => ({ ...old, controlBotLink: e.target.value }))}
        />
        <TextField
          margin="dense"
          label="Telegram Control Bot Secret Key"
          fullWidth
          value={data.telegramControlBotSecretKey}
          onChange={(e) => setData(old => ({ ...old, telegramControlBotSecretKey: e.target.value }))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant='contained'>
          Cancel
        </Button>
        <Button onClick={() => onAccept(data)} color="primary" variant='contained'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditModelDialog;