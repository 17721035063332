import React from 'react';
import useSWR from 'swr';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import * as api from '../../api/tons';
import { green, red, yellow } from '@mui/material/colors';
import { Delete } from '@mui/icons-material';
import ExternalLink from '../dumps/ExternalLink';
import { TonTransaction } from '../../types/ton';
import { Link } from 'react-router-dom';

function time2date(time: number) {
  return new Date(time * 1000).toLocaleString();
}

// convert long hex string to short
function shortHash(hash: string) {
  return `${hash.slice(0, 6)}...${hash.slice(-6)}`;
}

function shortMessage(message: string) {
  return message.length > 32 ? `${message.slice(0, 32)}...` : message;
}

function IsMessageUUID(uuid: string) {
  return uuid.length === 32;
}

const useTons = () => {
  const { data: tons = [], error, mutate } = useSWR<TonTransaction[]>(api.urls.List);
  return {
    tons,
    error,
    mutate
  };
}
// <ExternalLink href={`https://tonscan.com/transactions/${encodeURIComponent(transaction.txHash)}`}>
const TonsList: React.FC = () => {
  const { tons } = useTons();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account Address</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Transaction Hash</TableCell>
            <TableCell>Sender Address</TableCell>
            <TableCell>Destination Address</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tons.map((ton, index) => (
            <TableRow key={index}>
              <TableCell>
                <ExternalLink href={`https://tonscan.com/accounts/${encodeURIComponent(ton.accountAddress)}`}>
                  {shortHash(ton.accountAddress)}
                </ExternalLink>
              </TableCell>
              <TableCell>{time2date(ton.utime)}</TableCell>
              <TableCell>
                <ExternalLink href={`https://tonscan.com/transactions/${encodeURIComponent(ton.txHash)}`}>
                  {shortHash(ton.txHash)}
                </ExternalLink>
              </TableCell>
              <TableCell>
                <ExternalLink href={`https://tonscan.com/accounts/${encodeURIComponent(ton.senderAddress)}`}>
                  {shortHash(ton.senderAddress)}
                </ExternalLink>
              </TableCell>
              <TableCell>
                <ExternalLink href={`https://tonscan.com/accounts/${encodeURIComponent(ton.destinationAddress)}`}>
                  {shortHash(ton.destinationAddress)}
                </ExternalLink>
              </TableCell>
              <TableCell>{ton.value} TON</TableCell>
              <TableCell>
                {IsMessageUUID(ton.message) ? (
                  <Link to={`/transactions/${encodeURIComponent(ton.message)}`}>
                    {ton.message}
                  </Link>
                ) : shortMessage(ton.message)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TonsList;